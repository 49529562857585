import(/* webpackMode: "eager" */ "/home/racknaro/biozonos-site/biozonos/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/racknaro/biozonos-site/biozonos/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/racknaro/biozonos-site/biozonos/src/app/components/button/ButtonWater.jsx");
import(/* webpackMode: "eager" */ "/home/racknaro/biozonos-site/biozonos/src/app/layout/navbar/navbar.js");
import(/* webpackMode: "eager" */ "/home/racknaro/biozonos-site/biozonos/src/app/scss/pages/home/separator.scss");
import(/* webpackMode: "eager" */ "/home/racknaro/biozonos-site/biozonos/src/app/scss/pages/home/repairing.scss");
import(/* webpackMode: "eager" */ "/home/racknaro/biozonos-site/biozonos/src/app/scss/pages/home/hero.scss");
import(/* webpackMode: "eager" */ "/home/racknaro/biozonos-site/biozonos/src/app/scss/pages/home/section_intro.scss");
import(/* webpackMode: "eager" */ "/home/racknaro/biozonos-site/biozonos/src/app/scss/pages/home/section_services.scss");
import(/* webpackMode: "eager" */ "/home/racknaro/biozonos-site/biozonos/src/app/scss/pages/home/popular_products.scss");
import(/* webpackMode: "eager" */ "/home/racknaro/biozonos-site/biozonos/src/app/scss/components/btn-whsatpp.scss");
import(/* webpackMode: "eager" */ "/home/racknaro/biozonos-site/biozonos/src/app/scss/components/footer.scss")